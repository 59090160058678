'use strict';

import { VOYADO_EVENT_TRACK_PRODUCT_IMPRESSION } from 'shared/js/voyadoAnalyticsLoader';

export const rules = [
    {
        actionIds: ['Order-Confirm', 'Order-KlarnaConfirm', 'Adyen-ShowConfirmation'],
        callback: () => {
            // Reflects logic from legacy code-base @see app-project/app_hsng/cartridge/templates/default/checkout/confirmation/confirmation.isml:77
            addEventListener('load', () => {
                document.dispatchEvent(
                    new CustomEvent('Voyado-monitor-empty-cart', {
                        detail: {
                            empty: true
                        }
                    })
                );
            });
        }
    },
    {
        actionIds: ['Product-Show'],
        callback: () => {
            addEventListener('load', () => {
                document.dispatchEvent(
                    new CustomEvent(VOYADO_EVENT_TRACK_PRODUCT_IMPRESSION, {
                        detail: {
                            categoryName: $('.breadcrumb').text().replace(/\n/g, '').replace(/\s+/g, ' ').trim(),
                            itemId: $('#product-detail').data('pid'),
                            locale: window.currentLocale,
                            contactId: $('[data-voyado-contact-id]').data('voyado-contact-id')
                        }
                    })
                );
            });
        }
    }
];
