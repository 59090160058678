// noinspection DuplicatedCode

'use strict';

import { rules } from './voyado/pageEvents';
import { getLogger } from 'shared/js/dev-mode';

const logger = getLogger();

const VOYADO_EVENT_MONITOR_CART = 'Voyado-monitor-cart';
const VOYADO_EVENT_MONITOR_EMPTY_CART = 'Voyado-monitor-empty-cart';
const VOYADO_EVENT_TRACK_PRODUCT_IMPRESSION = 'Voydo-monitor-product-impression';
const VOYADO_SCRIPT_LOADED = 'Voyado-script-loaded';

const getVoyadoLocale = (sourceLocale) => {
    let result = 'sv-SE';
    sourceLocale = sourceLocale || window.locale;
    if (sourceLocale === 'no_NO') {
        result = sourceLocale.replace('no_NO', 'nb-NO');
    } else if (sourceLocale) {
        result = sourceLocale.replace('_', '-');
    }
    return result;
};

const trackCart = (uuid, isEmpty, basketItems, basketLocale) => {
    let payload = {
        locale: getVoyadoLocale(basketLocale),
        cartRef: uuid
    };

    if (!isEmpty) {
        if (basketItems.length == 0) {
            isEmpty = true;
        } else {
            payload.items = [];
            basketItems.forEach(function (item) {
                payload.items.push({
                    itemId: item.id,
                    quantity: item.quantity
                });
            });
        }
    } else {
        payload.cartRef = $('.confirmation-summary').find('.order-number')[0].getAttribute('data-basket-uuid');
    }

    if (payload.cartRef) {
        window.va(isEmpty ? 'emptyCart' : 'cart', payload);
    }
};

const trackProductImpression = (categoryName, itemId, locale, contactId) => {
    if (!itemId || !locale || !categoryName) {
        return;
    }

    let payload = {
        locale: getVoyadoLocale(locale),
        categoryName: categoryName,
        itemId: itemId,
    };

    if (contactId) {
        payload.contactId = contactId;
    }

    window.va('productview', payload);
};

const initialize = () => {
    document.addEventListener(
        VOYADO_EVENT_MONITOR_CART,
        function (event) {
            trackCart(event.detail.uuid, event.detail.empty, event.detail.items, event.detail.locale);
        },
        false
    );

    document.addEventListener(
        VOYADO_EVENT_MONITOR_EMPTY_CART,
        function (event) {
            trackCart(null, event.detail.empty);
        },
        false
    );

    document.addEventListener(
        VOYADO_EVENT_TRACK_PRODUCT_IMPRESSION,
        function (event) {
            trackProductImpression(event.detail.categoryName, event.detail.itemId, event.detail.locale, event.detail.contactId);
        },
        false
    );


    // Process rules.
    rules.forEach((rule) => {
        if (!rule || !Array.isArray(rule.actionIds)) {
            return;
        }
        try {
            if (window.actionId && rule.actionIds.indexOf(window.actionId) > -1) {
                rule.callback();
            }
        } catch (exception) {
            logger.error(exception);
        }
    });
};

export const execute = () => {
    let voyadoEnabled = $('.data-holder').data('voyado-analytics-enabled');

    if (voyadoEnabled) {
        document.addEventListener(
            VOYADO_SCRIPT_LOADED,
            function () {
                initialize();
            },
            false
        );
    }
};

export default {
    execute,
    VOYADO_EVENT_MONITOR_CART,
    VOYADO_EVENT_MONITOR_EMPTY_CART,
    VOYADO_EVENT_TRACK_PRODUCT_IMPRESSION,
    VOYADO_SCRIPT_LOADED
};
